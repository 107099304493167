window.cachedPages = [];
window.isLoadedPage = false;
window.imagesCanvasArray = [];

var imagesLoaded = require('imagesloaded');

var pageWrapper = document.querySelector(".all-page-wrapper");
var preloaderDiv = document.querySelector(".page-preloader");
formatePage(pageWrapper);
var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

function updateLoader(percentsLoaded) {
  console.log(percentsLoaded);
} //Вначале определяем то, что будет загружаться, и потом если загрузилось - показываем страницу


function formatePage(pageData, modalData) {
  var imagesArray = [];
  var withVideo = false;
  var picturesLoaded = false;
  var videoLoaded = false;
  var percentageLoaded = 0;
  var currentImagesLoaded = 0;
  document.querySelectorAll(".jsPreload").forEach(function (preloadBox) {
    // work with picture RETINA
    preloadBox.querySelectorAll("picture").forEach(function (thisPicture) {
      var imageToLoad;
      var image_src;
      var image_srcset; //mobile devices

      if (Modernizr.device_xs) {
        if (!image_src) {
          image_src = thisPicture.querySelector('img').src;
        }

        if (!image_src) {
          image_src = thisPicture.querySelector('img').srcset;
        }
      } //desktop
      else {
        thisPicture.querySelectorAll('source').forEach(function (thisSource) {
          if (thisSource.media === "(min-width: 768px)") {
            if (Modernizr.webp) {
              if (thisSource.type === "image/webp") {
                image_srcset = thisSource.srcset;
              }
            } else {
              if (thisSource.type != "image/webp") {
                image_srcset = thisSource.srcset;
              }
            }
          } //Если в picture нет source webp или srcset не найден, берем любой.


          if (!image_srcset) {
            if (thisSource.media === "(min-width: 768px)") {
              image_srcset = thisSource.srcset;
            }
          }
        });

        if (Modernizr.retina) {
          var lastsrcset = image_srcset.split(',');
          var lastsrc = lastsrcset[lastsrcset.length - 1];
          var lastpuresrc = lastsrc.split(' ');
          image_src = lastpuresrc[1];

          if (!image_src) {
            image_src = image_srcset;
          }

          console.log(image_src);
        } else {
          image_src = image_srcset.split(',')[0];
        }
      }

      var img = new Image();
      img.src = image_src; //console.log(image_src)

      imageToLoad = img;
      imagesArray.push(imageToLoad);
    });
    preloadBox.querySelectorAll("img").forEach(function (thisImg) {
      if (!thisImg.closest("picture")) {
        var image_src = thisImg.src;
        var imageToLoad;

        if (Modernizr.retina) {
          var imageSrcset = thisImg.srcset;
          imageSrcset = imageSrcset.slice(0, -2).split(' ');
          image_src = imageSrcset[0];
        }

        if (image_src) {
          var img = new Image();
          img.src = image_src;
          imageToLoad = img;
          imagesArray.push(imageToLoad);
        }
      }
    }); //если нужно сначала загрузить видео!

    preloadBox.querySelectorAll(".jsLoadVideo").forEach(function (videoElement) {
      withVideo = true;
      var videoSource = videoElement.querySelector("source");
      var videoDataSrc = videoSource.dataset.src;
      videoSource.setAttribute('src', videoDataSrc);
      videoElement.load();
      var CheckVideoDownLoadTimer = setInterval(alertFunc, 300);

      function alertFunc() {
        console.log(videoElement);

        if (!videoLoaded) {
          videoElement.pause();
        }

        if (videoElement.readyState >= 4) {
          clearInterval(CheckVideoDownLoadTimer);
          videoLoaded = true;
          videoElement.play();
        }
      }
    });
    preloadBox.querySelectorAll(".jsLoadVideoBoxFirst").forEach(function (videoElement) {
      withVideo = true;
      videoLoaded = false;
      var video = document.createElement('video');
      var sourceMP4 = document.createElement("source");
      var extension = ".mp4";
      sourceMP4.src = videoElement.dataset.src + extension;
      sourceMP4.type = "video/mp4";
      video.autoplay = true;
      video.loop = true;
      video.muted = true;
      video.playsInline = true;
      video.append(sourceMP4);
      videoElement.append(video);
      console.log(video);
      var CheckVideoDownLoadTimer = setInterval(alertFunc, 300);

      function alertFunc() {
        video.pause();
        console.log(video.readyState);

        if (video.readyState >= 4) {
          clearInterval(CheckVideoDownLoadTimer);
          videoElement.setAttribute('data-loaded', true);
          videoLoaded = true;
          console.log("firstVideoLoaded");
        }
      }
    });
  });
  var imgLoad = imagesLoaded(imagesArray);
  imgLoad.on('progress', function (instanse, image) {
    var result = image.isLoaded ? 'loaded' : 'broken';
    ++currentImagesLoaded;
    percentageLoaded = parseInt(100 / imagesArray.length * currentImagesLoaded);
    console.log(percentageLoaded);
    console.log('image is ' + result + ' for ' + image.img.src);
  });
  imgLoad.on('done', function () {
    document.querySelector(".jsPreload").classList.add('loadedPicture');
    console.log("images loaded");
    picturesLoaded = true;
  });
  var CheckLoadingProgress = setInterval(checkerLoadingProgress, 1000);

  function checkerLoadingProgress() {
    updateLoader(percentageLoaded);
  }

  var loadingTime = 0;
  var CheckLoadingTime = setInterval(checkerLoading, 300);

  function checkerLoading() {
    loadingTime += 0.3;
    console.log("loadingTime: " + loadingTime);

    if (loadingTime >= 0.6) {
      preloaderDiv.classList.add('loader-long');
    }

    if (!withVideo) {
      console.log("without video");

      if (picturesLoaded === true) {
        clearInterval(CheckLoadingTime);
        clearInterval(CheckLoadingProgress);
        updateLoader(99);
        launch();
      }
    } else {
      console.log("with video... videoLoaded: " + videoLoaded);

      if (videoLoaded === true && picturesLoaded === true) {
        console.log("videoLoaded true");
        clearInterval(CheckLoadingTime);
        clearInterval(CheckLoadingProgress);
        updateLoader(99);
        launch();
      }
    }
  }
}

function launch() {
  window.isLoadedPage = true;
  var url = window.location.href.replace(/^.*\/\/[^\/]+/, '');
  window.cachedPages.push(url);
  document.querySelector("body").classList.add('loaded-page');
}

if (window.innerWidth < 768) {
  document.querySelectorAll(".js_xsNoChangeVH").forEach(function (element) {
    element.style.height = window.innerHeight + 60 + "px";
  });
}